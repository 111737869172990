<template>
  <div>
    <div class="installPrompt" v-if="isPluginInstalled">
      <div class="installPromptBox">
        <div class="conBox">
          <img
            class="RAGlogoImg"
            :src="require('@/assets/icon/rakumartandGoogle.svg')"
            alt=""
          />
          <span
            >NUEVO: Acabamos de lanzar una extensión para Google Chrome 🎉</span
          >
          <a
            href="https://chrome.google.com/webstore/detail/b%C3%BAsqueda-por-imagen-en-ra/ppgnkefpaocohpjnmldgigkpjccdikbe?utm_source=app-launcher&authuser=0"
            target="_blank"
            class="toGooGleShopBtn"
          >
            <svg
              version="1.1"
              class="linkIcon"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 194.818 194.818"
              style="enable-background:new 0 0 194.818 194.818;"
              xml:space="preserve"
            >
              <g>
                <path
                  d="M185.818,2.161h-57.04c-4.971,0-9,4.029-9,9s4.029,9,9,9h35.312l-86.3,86.3c-3.515,3.515-3.515,9.213,0,12.728
		c1.758,1.757,4.061,2.636,6.364,2.636s4.606-0.879,6.364-2.636l86.3-86.3v35.313c0,4.971,4.029,9,9,9s9-4.029,9-9v-57.04
		C194.818,6.19,190.789,2.161,185.818,2.161z"
                />
                <path
                  d="M149,77.201c-4.971,0-9,4.029-9,9v88.456H18v-122h93.778c4.971,0,9-4.029,9-9s-4.029-9-9-9H9c-4.971,0-9,4.029-9,9v140
		c0,4.971,4.029,9,9,9h140c4.971,0,9-4.029,9-9V86.201C158,81.23,153.971,77.201,149,77.201z"
                />
              </g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
            </svg>
            Instala la Extensión de Chrome
          </a>
          <div class="closeBtn" @click="closePromptClick">
            <img :src="require('@/assets/icon/closeWhite.png')" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="EUHeMa" :class="{ downShow: isPluginInstalled }">
      <div id="HomePageTop">
        <div class="qiTa" id="qitasss">
          <div class="zhiNan">
            <span @click="$fun.routerToPage('/quienes-somos', true)">
              <!-- 我们是谁 -->{{ $fanyi("我们是谁") }}
            </span>
            <span @click="$fun.routerToPage('/ventajas', true)">
              {{ $fanyi("优势") }}
            </span>

            <span @click="$fun.routerToPage('/guia-de-usuario', true)">
              {{ $fanyi("如何工作") }}
            </span>
            <span @click="$fun.routerToPage('/centro-de-ayuda', true)">
              {{ $fanyi("帮助中心") }}
            </span>
          </div>
          <div class="huiLv">
            Conversión Yuan Chino a Euro: <span class="numd">1￥ CN</span> =
            <font class="num numd"
              >{{ $fun.huilvqianf($store.state.exchangeRate) }}€</font
            >
          </div>
        </div>
        <div class="fgx"></div>
        <div id="HomePageTopCon">
          <router-link to="/" class="logoBox">
            <img
              style="  width:185px;height: 46px;"
              id="logo"
              @click="backHome"
              src="../../assets/hometop/logo-rakumart-europe.svg"
              alt=""
          /></router-link>
          <div class="searchBoxDiv">
            <el-select
              v-model="value"
              :placeholder="$fanyi('所有类别')"
              clearable
              @change="$store.commit('goodsListActiveId', value)"
            >
              <el-option :label="$fanyi('所有类别')" value=""> </el-option>
              <el-option
                v-for="item in options"
                :key="item.chinese_name"
                :label="item.translation_name"
                :value="item.chinese_name"
                @click.native="
                  $fun.routerToPage(
                    '/CommoditySearch?keyword=' +
                      item.translation_name +
                      '&CN=' +
                      $fun.toCode(item.chinese_name)
                  )
                "
              >
              </el-option>
            </el-select>
            <div class="fg"></div>
            <!-- 请输入您想搜索的产品关键字 -->
            <el-input
              v-model="$store.state.searchInfo"
              :placeholder="$fanyi('请输入您想搜索的产品关键字')"
              @keydown.enter.native="search"
            >
            </el-input>
            <el-upload
              class="upImg"
              action="https://laravel.rakumart.es/api/common/uploadFile"
              list-type="picture-card"
              :before-upload="befoUpload"
              :on-success="successup"
              :show-file-list="false"
              :http-request="httpup"
              accept=".jpg,.jpeg,.png"
            >
              <img src="../../assets/hometop/camara.svg" alt="" />
            </el-upload>
            <el-button @click="search">
              <img src="../../assets/newImg/smallss.svg" />
            </el-button>
          </div>

          <div class="operate">
            <div v-if="!token" class="userDetail notLogin">
              <img src="../../assets/base-user.png" alt="" />
              <span>
                <span>
                  <span
                    class="linkSpan"
                    @click="
                      $store.commit('getactivePage', $route.fullPath);
                      $fun.routerToPage('/login');
                    "
                    ><!-- 登录 -->{{ $fanyi("登录") }}</span
                  >
                </span>
                <span class="fg"> / </span>
                <span>
                  <span class="linkSpan" @click="$fun.routerToPage('/register')"
                    ><!--  -->{{ $fanyi("注册") }}</span
                  >
                </span>
              </span>
            </div>
            <div v-else class="userDetail">
              <img
                class="userHeadImg"
                style="cursor: pointer"
                @click="$fun.routerToPage('/user/index')"
                svg
                :src="userInfo.head_img"
                v-if="userInfo.head_img"
              />
              <img
                class="userHeadImg"
                style="cursor: pointer"
                @click="$fun.routerToPage('/user/index')"
                v-else
                src="../../assets/base-user.png"
              />
              <div class="userLoginInfoBox">
                <p class="toUserIndex">Hola&nbsp;{{ userInfo.user_name }}</p>
                <p>
                  <span
                    class="routerBtn"
                    @click="
                      $fun.routerToPage('/user/index');
                      getUser();
                    "
                  >
                    {{ $fanyi("个人中心") }}
                  </span>
                  <span style="font-size: 12px"> / </span>
                  <span class="routerBtn" @click="loginOut"
                    >{{ $fanyi("退出登录") }}
                  </span>
                </p>
              </div>

              <div class="dropDownBox" v-if="false">
                <span
                  @click="
                    $fun.routerToPage('/user/index');
                    getUser();
                  "
                >
                  {{ $fanyi("个人中心") }}
                </span>
                <!-- <span> {{ $fanyi("意见反馈") }} </span> -->
                <span @click="loginOut">{{ $fanyi("退出登录") }} </span>
              </div>
            </div>
            <!-- <div class="huilv">
              1￥ = <font class="num">{{ $store.state.exchangeRate }}</font
              >€
            </div> -->
            <div class="cartBtn" ref="end">
              <button type="button" @click="openCart">
                <img
                  src="../../assets/newImg/gouwuche.svg"
                  alt=""
                  id="gouwuche"
                />
                {{ $fanyi("购物车") }}
                <span class="cartNum" v-if="goodsNum > 0">{{ goodsNum }}</span>
              </button>
            </div>
          </div>
        </div>
        <div class="hr" v-if="showClassificationOfGoodsVue"></div>
        <!-- 必须使用v-if来控制分类显不显示，因为分类加载请求接口时间较长，假如使用v-show当打开新页面时会请求分类列表获取接口浪费较长时间 -->
        <!-- <ClassificationOfGoodsVue
          id="classBox"
          v-if="showClassificationOfGoodsVue"
        ></ClassificationOfGoodsVue> -->
        <sideBar ref="sideBar" />
      </div>
    </div>
    <!-- <div class="zhanWeiDiv" v-show="showClassificationOfGoodsVue"></div> -->
  </div>
</template>

<script>
import Compressor from "compressorjs";
import ClassificationOfGoodsVue from "../homePage/ClassificationOfGoods.vue";
import sideBar from "../homePage/sideBar.vue";
export default {
  data() {
    return {
      logininfo: localStorage.getItem("logininfo"),
      value: "",
      pageLoading: null,
      showQita: true, //显示其他
      closePrompt: document.cookie.indexOf("closePrompt") != -1,
      isPluginInstalled: false,
      imageId: "",
      tipagedata: "",
    };
  },
  created() {
    // document.cookie = "closePrompt=true";
    // document.cookie = "closePrompt=; expires=Thu, 01 Jan 1970 00:00:00 GMT";//删除cookie
    // console.log(document.cookie.indexOf("closePrompt") != -1);
    // 防止搜索栏分类在跳转页面时因重新加载而清空
    this.value = this.$store.state.goodsListActiveId;
    this.$store.dispatch("goodsToCartNum");
    // this.getClassData();
    setTimeout(() => {
      this.isPluginInstalled =
        !document.getElementById("rakumart-plugin-dom") && !this.closePrompt;
    }, 300);
  },
  components: {
    sideBar,
    ClassificationOfGoodsVue,
  },
  computed: {
    searchInfo() {
      return this.$store.state.searchInfo;
    },
    goodsNum() {
      return this.$store.state.cartCount;
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
    options() {
      return this.$store.state.goodsList;
    },
    token() {
      if (this.$store.state.userInfo) {
        return true;
      } else {
        return false;
      }
    },
    showClassificationOfGoodsVue() {
      //console.log(this.$route.name);
      if (["homepage", "CommoditySearch"].indexOf(this.$route.name) != -1) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.changeHeight();
  },

  methods: {
    // 关闭推广
    closePromptClick() {
      this.isPluginInstalled = false;
      this.closePrompt = true;
      document.cookie = "closePrompt=true";
    },
    openCart() {
      if (localStorage.getItem("user_token")) {
        this.$fun.routerToPage("/cart");
      } else {
        this.$store.commit("getactivePage", "/cart");
        this.$store.commit("getshowLoginDialog", true); //打开登录弹窗
      }
    },
    getUser() {
      this.$api.EuropegetUserInfo().then((res) => {
        this.$store.commit("userData", res.data);
      });
    },
    // 获取搜索框分类数据
    getClassData() {
      this.$api
        .getShopGroup()
        .then((result) => {
          this.options = result.data;
          this.options.unshift({
            chinese_name: "",
            translation_name: "Todos los",
            id: -1,
          });
          //console.log(this.options);
        })
        .catch((err) => {});
    },
    changeHeight() {
      let store = this.$store;
      window.onscroll = function() {
        // if (router.history.current.fullPath == "/") {
        let scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        if (scrollTop >= 190) {
          if (document.getElementsByClassName("EUHeMa")) {
            document.getElementsByClassName("EUHeMa")[0].style.height = "100px";
          }
          if (document.getElementById("qitasss")) {
            document.getElementById("qitasss").style.height = "0";
          }
          // document.getElementById("HomePageTopCon").style.height = "50px";
          if (document.getElementById("mask")) {
            document.getElementById("mask").style.top = "150px";
          }
          // document.getElementById("classBox").style.display = "none";
        } else if (scrollTop <= 100) {
          if (document.getElementsByClassName("EUHeMa")) {
            document.getElementsByClassName("EUHeMa")[0].style.height = "186px";
          }
          if (document.getElementById("qitasss")) {
            document.getElementById("qitasss").style.height = "59px";
          }
          if (document.getElementById("HomePageTopCon")) {
            document.getElementById("HomePageTopCon").style.height = "107.28px";
          }

          if (document.getElementById("mask")) {
            document.getElementById("mask").style.top = "220px";
          }
        }
        // 是否显示top键 (ps:topBtn不在这个页面里，在sideBar.vue里)

        if (scrollTop >= 500) {
          document.getElementById("topBtn").style.display = "block";
        } else if (scrollTop <= 530) {
          document.getElementById("topBtn").style.display = "none";
        }
        // }
        store.commit("settopScrollMoreHeight", scrollTop);
      };
    },
    // 返回首页
    backHome() {
      this.$store.commit("getsearchInfo", "");
    },
    // 跳转搜索页
    search() {
      let regtaobao = RegExp(/taobao/);
      let reg1688 = RegExp(/1688/);
      let regtmall = RegExp(/tmall/);
      let regrakumart = RegExp(/goods_id/);
      let id = "";
      var reg1 = /1688.com\/offer\/([0-9]+)/;
      var reg4 = /m\.1688\.com.*offerId=([0-9]{10,})/; //1688手机
      var reg2 = /[?|&]id=(\d{10,15})/;
      var reg3 = /[?|&]goods_id=(\d{10,15})/;
      // 判断是哪个网站的商品链接
      if (regtaobao.test(this.$store.state.searchInfo)) {
        id = this.$store.state.searchInfo.match(reg2)[1];
        this.$fun.toCommodityDetails(id, "taobao");
        return false;
      } else if (reg1688.test(this.$store.state.searchInfo)) {
        if (this.$store.state.searchInfo.match(reg1) == null) {
          id = this.$store.state.searchInfo.match(reg4)[1];
        } else {
          id = this.$store.state.searchInfo.match(reg1)[1];
        }
        this.$fun.toCommodityDetails(id, "1688");
        return false;
      } else if (regtmall.test(this.$store.state.searchInfo)) {
        id = this.$store.state.searchInfo.match(reg2)[1];
        this.$fun.toCommodityDetails(id, "tmall");
        return false;
      } else if (regrakumart.test(this.$store.state.searchInfo)) {
        // return false;
        id = this.$store.state.searchInfo.match(reg3)[1];
        this.$fun.toCommodityDetails(id, "tmall");
      }
      // --------------------------------------------------
      // 设置分类选择框的已选中id
      let selId = this.value
        ? this.options.find((item) => item.chinese_name == this.value).id
        : "";
      // 如果是商品搜索页面并且路由上的id和选择器上的id不一致就重设路由
      if (
        this.$route.name == "CommoditySearch" &&
        selId != this.$route.query.group_id
      ) {
        //console.log("重设路由");
        this.$fun.routerToPage({
          query: {
            keyword: this.$store.state.searchInfo,
          },
        });
      }
      // 如果搜索的是同一词条就刷新页面
      if (this.$store.state.searchInfo == this.$route.query.keyword) {
        this.$router.go(0);
        return false;
      }
      // 如果关键词为空就不跳转
      if (!this.$store.state.searchInfo) {
        this.$message(this.$fanyi("请输入关键词搜索"));
        return false;
      }
      // 跳转
      this.$fun.routerToPage(
        "/CommoditySearch?keyword=" +
          this.$store.state.searchInfo +
          "&group_id=" +
          selId
      );
    },
    // 退出登录
    loginOut() {
      localStorage.removeItem("backgroundOperationID");
      sessionStorage.removeItem("backendPersonnel");
      localStorage.removeItem("user_token");
      sessionStorage.removeItem("user_id");

      this.$store.commit("userData", null);
      this.$store.commit("setcartCount", 0);
      let requireAuth = this.$route.matched[0].meta.requireAuth;
      if (requireAuth) {
        this.$fun.routerToPage("/");
      }
    },
    befoUpload(file) {
      const isJPG =
        ["image/jpeg", "image/png", "image/jpg"].indexOf(file.type) != -1;
      //console.log(file.type);
      if (!isJPG) {
        this.$message.error(this.$fanyi("请上传图片"));
        return isJPG;
      }

      this.pageLoading = this.$loading({
        lock: true,
        text: this.$fanyi("图片上传中"),
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, .7)",
      });
    },
    blobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    },

    // 图片上传事件
    httpup(file) {
      var _this = this;
      this.imageId = "";
      this.tipagedata = "";
      new Compressor(file.file, {
        quality: 0.2,
        success(result) {
          // console.log("skdfh", result); // 处理后的文件流BLOB
          // 以下为文件本地保存、下载查看压缩后的文件效果和大小，可省略
          // const blobUrl = window.URL.createObjectURL(result);
          // const a = document.createElement("a");
          // a.download = result.name;
          // a.style.display = "none";
          // a.href = blobUrl;
          // // 触发点击
          // document.body.appendChild(a);
          // a.click();
          // // 然后移除
          // document.body.removeChild(a);
          // 以下为将文件加载到formData中以便文件上传

          _this.blobToBase64(result).then((reader) => {
            _this.getImageId(reader);
          });
          let formData = new FormData();
          formData.append("file", result);
          //文件上传请求
          var xhr = new XMLHttpRequest();
          xhr.open(
            "POST",
            "https://laravel.rakumart.es/api/common/uploadFile",
            true
          );
          xhr.onload = () => {
            if (xhr.status === 200) {
              const res = JSON.parse(xhr.responseText);
              _this.tipagedata = res.data;
              _this.successup();
            } else {
              // console.log("An error occurred while uploading the file.");
            }
          };
          xhr.send(formData);
        },
        error(err) {
          //错误处理
          console.log(err.message);
        },
      });
    },

   //获取图片id
    getImageId(val) {
      let that = this;
      var img = new Image();
      img.src = val;
      img.crossOrigin = "Anonymous"; // 如果图片需要跨域访问，可以设置这个属性
      img.onload = function() {
        let width = img.width;
        let height = img.height;
        if (width > height) {
          if (width > 200) {
            height *= 200 / width;
            width = 200;
          }
        } else {
          if (height > 200) {
            width *= 200 / height;
            height = 200;
          }
        }
        var canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        var base64 = canvas.toDataURL("image/png", 0.5); // 可以改为image/jpeg，image/webp等其他格式
        let index = base64.indexOf("base64,");
        let base64String = base64.substr(index + 7, base64.length);
        let datas = {
          url: "/1/com.alibaba.fenxiao.crossborder/product.image.upload",
          uploadImageParam: JSON.stringify({
            imageBase64: base64String,
          }),
        };
        that.$api.uploadImagePic(datas).then((res) => {
          if (res.data.code != 200)
            return that.$message.error(that.$fanyi(res.data.message));
          that.imageId = res.data.result;
          that.successup();
        });
      };
    },

    // 图片上传成功事件
    successup() {
      console.log(this.imageId, this.tipagedata);
      if (this.imageId && this.tipagedata) {
        this.pageLoading.close();
        this.$store.commit("getsearchImg", this.tipagedata);

        this.$fun.routerToPage(
          "/CommoditySearch?type=imgSearch&imgUrl=" +
            this.tipagedata +
            "&imageId=" +
            this.imageId
        );
      }
      return;
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "../../css/mixin";

.EUHeMa {
  height: 186px;
  z-index: 9;

  #HomePageTop {
    width: 100%;
    background-color: white;
    position: fixed;
    top: 0px;
    z-index: 999;
    border-bottom: solid 1px rgba(61, 61, 61, 0.1);
    box-shadow: 0px 29px 9px -29px rgba($color: #000000, $alpha: 0.07);

    > .qiTa {
      width: $pageWidth;
      margin: 0 auto;
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 59px;

      .zhiNan {
        padding-left: 6px;

        span {
          margin-right: 30px;
          font-size: 13px;

          font-weight: 500;
          color: #767676;
          cursor: pointer;

          &:hover {
            .dropDownBox {
              height: 95px;
            }
          }

          position: relative;

          .dropDownBox {
            transition: 0.3s;
            position: absolute;
            top: 30px;
            left: 50%;
            transform: translate(-50%, 0);
            width: 120px;
            height: 0;
            overflow: hidden;
            z-index: 23;
            background: #ffffff;
            box-shadow: 0px 0px 10px 0px rgba(136, 136, 136, 0.2);
            border-radius: 10px;

            > span {
              display: block;
              cursor: pointer;
              text-align: center;
              line-height: 32px;
              width: 120px;
              height: 32px;
              font-size: 16px;

              font-weight: 400;

              &:hover {
                color: $homePageSubjectColor;
                background: #e2e5ff;
              }
            }
          }
        }
      }

      .huiLv {
        width: 373.53px;
        height: 34.89px;
        border: 1px solid #dcdcdc;
        border-radius: 4px;
        line-height: 0px;
        padding-top: 12px;
        line-height: 0.5em;
        font-size: 13px;
        font-weight: 500;
        color: #767676;
        text-align: center;

        .numd {
          font-weight: bolder;
        }

        .num {
          color: $homePageSubjectColor;
        }
      }
    }

    > .fgx {
      width: 100%;
      height: 1px;
      background-color: rgba(61, 61, 61, 0.1);
    }

    > #HomePageTopCon {
      width: $pageWidth;
      margin: 0 auto;
      height: 107.28px;
      min-height: 65px;
      display: flex;
      align-items: center;
      transition: linear 0.2s;
      display: flex;

      > div {
        display: flex;
        align-items: center;

        // logo
        &.logoBox {
          justify-content: flex-start;
          width: 205.63px;
          height: 66.28px;
          padding: 10px;

          #logo {
            transition: 0.5s;
            width: 100%;
            min-width: 77.5px;
            cursor: pointer;
          }
        }

        &.searchBoxDiv {
          width: 800px;
          height: 54px;
          justify-content: space-between;
          display: flex;
          border: 2px solid #1e2997;
          border-radius: 3px;
          margin: 0 auto 0 24px;

          /deep/.el-select {
            flex: 0 0 185px;

            .el-input__inner {
              border: none;
              font-size: 13px;
              font-weight: 400 !important;

              &::placeholder {
                font-size: 13px;
                color: black !important;
              }
            }
          }

          .fg {
            height: 20px;
            width: 1px;
            background-color: #6d6d6d;
          }

          /deep/.el-input {
            flex: 1;

            .el-input__inner {
              border: none;
              font-size: 13px;
              font-weight: 500;
              color: black;
            }
          }

          /deep/.el-upload {
            width: 72px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;

            img {
              width: 30px;
              height: 30px;
              opacity: 0.5;
            }
          }

          /deep/.el-button {
            width: 64px;
            height: 54px;
            border: none;
            background: #1e2997;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: -1px;

            img {
              width: 24px;
              height: 24 px;
            }
          }
        }

        &.operate {
          .userDetail {
            width: 200px;

            $userImgSize: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            position: relative;

            &.notLogin {
              text-align: right;
              width: 234.56px;
              justify-content: flex-end;

              img {
                width: 22px;
                height: 22px;
                margin-right: 5.5px;
              }
            }

            &:hover {
              .dropDownBox {
                height: 64px;
              }
            }

            .dropDownBox {
              transition: 0.3s;
              position: absolute;
              top: 100%;
              left: 49px;
              width: 120px;
              height: 0;
              overflow: hidden;
              background: #ffffff;
              box-shadow: 0px 0px 10px 0px rgba(136, 136, 136, 0.2);
              border-radius: 10px;
              z-index: 777;

              > span {
                display: block;
                cursor: pointer;
                text-align: center;
                line-height: 32px;
                width: 120px;
                height: 32px;
                font-size: 16px;

                font-weight: 400;

                &:hover {
                  color: $homePageSubjectColor;
                  background: #e2e5ff;
                }
              }
            }

            // 用户头像大小
            $userHeadImgSize: 22px;

            .userHeadImg {
              border-radius: 50%;
              width: $userHeadImgSize;
              height: $userHeadImgSize;
              margin-right: 6px;
            }

            .moRenTouXiang {
              width: $userHeadImgSize;
              height: $userHeadImgSize;
              margin-right: 5px;
            }

            span {
              font-size: 14px;

              font-weight: 500;
              color: #000000;

              > * {
                text-decoration: underline;
              }

              .fg {
                text-decoration: unset;
              }

              &:nth-child(3) a {
                color: $homePageSubjectColor;
              }
            }

            .userLoginInfoBox {
              .toUserIndex {
                cursor: pointer;
                font-size: 13px;
                text-decoration: unset !important;

                &:hover {
                  color: $homePageSubjectColor;
                }
              }

              .routerBtn {
                cursor: pointer;
                font-size: 13px;

                &:hover {
                  color: $homePageSubjectColor;
                }
              }
            }
          }

          .huilv {
            margin-right: 20px;
          }

          .cartBtn {
            button {
              display: flex;
              border-radius: 33px;
              align-items: center;
              font-size: 14px;
              font-weight: 500;
              padding: 10px 15px;
              position: relative;

              img {
                margin-right: 5.5px;
              }

              .cartNum {
                width: 19px;
                height: 19px;
                position: absolute;
                top: 0px;
                left: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #e3e5fd;
                border-radius: 50%;
                font-size: 10px;
                color: #212a91;
              }
            }
          }
        }
      }
    }

    > .hr {
      border-bottom: #efefef solid 1px;
    }
  }
}
.installPrompt {
  $height: 54px;
  height: $height;
  width: 100%;

  .installPromptBox {
    height: $height;
    top: 0;
    position: fixed;
    width: 100%;
    z-index: 110;
    background-color: #4f6fff;

    .conBox {
      width: 1400px;
      height: $height;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .RAGlogoImg {
        width: 73px;
        height: 30px;
        margin-right: 13px;
      }
      span {
        font-size: 14px;
        font-weight: 600;
        color: white;
        margin-right: 13px;
        margin-bottom: 2px;
      }
      .toGooGleShopBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 3px;
        width: 256px;
        height: 27px;
        font-size: 13px;
        font-weight: 600;
        color: #1a73e8;
        background-color: #ffffff;
        border-style: solid;
        border-width: 2px 2px 2px 2px;
        border-color: #ffffff;
        transition: 0.3s;
        .linkIcon {
          width: 13px;
          height: 13px;
          fill: #1a73e8;
          margin-right: 5px;
          font-weight: bold;
          transition: 0.3s;
        }

        &:hover {
          color: white;
          background-color: #1a73e8;
          .linkIcon {
            fill: white;
          }
        }
      }
      .closeBtn {
        position: fixed;
        cursor: pointer;
        top: 17px;
        right: 20px;
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
.EUHeMa.downShow {
  #HomePageTop {
    top: 54px;
  }
}
#classBox {
  display: block;
}

.zhanWeiDiv {
  height: 80px;
}
</style>
